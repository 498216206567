<template>
  <div>
    <b-row>
      <b-col cols="2" sm="6" md="6">
        <b-button @click.prevent="openModal" class="btn-filter mr-2">
          <span class="d-none d-sm-flex align-items-center">
            <span class="mr-2">Select Items </span>
            <font-awesome-icon icon="chevron-right" class="pointer" />
          </span>
          <font-awesome-icon icon="chevron-right" class="d-sm-none" />
        </b-button>
        <span class="text-black">Selected {{ rows }} list</span>
      </b-col>
      <b-col cols="10" sm="6" md="6">
        <b-input-group class="mb-2">
          <b-form-input
            type="text"
            id="header-search-bar"
            class="search-bar"
            @keyup.enter="handleSearch"
            placeholder="Search Product Name"
            v-model="filter.search"
          >
          </b-form-input>
          <b-input-group-append is-text>
            <b-iconstack font-scale="2" type="submit" @click="handleSearch">
              <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
            </b-iconstack>
          </b-input-group-append>
          <button
            @click="importProduct"
            type="button"
            class="btn button btn-mobile ml-2"
          >
            <font-awesome-icon icon="file-import" class="d-md-none" />
            <span class="d-none d-md-block">
              <font-awesome-icon icon="file-import" class="pointer" />
              <span class="btn-text">Import </span>
            </span>
          </button>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          responsive
          hover
          :fields="fields"
          striped
          :items="items"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>

          <template v-slot:cell(description)="{ item }">
            <div class="font-bold">{{ item.name }}</div>

            <div v-html="item.description"></div>
          </template>
          <template v-slot:cell(imageUrl)="data">
            <div class="position-relative picture-text pic-table">
              <img
                v-if="data.item.imageUrl"
                class="product-image pt-0"
                :src="data.item.imageUrl"
                alt="product image"
              />
              <div v-else>
                <img
                  class="product-image pt-0"
                  :src="default_image"
                  alt="default Image"
                  srcset=""
                />
              </div>
            </div>
          </template>
          <template v-slot:cell(price)="{ item }">
            <div class="price-baht">
              {{ item.price | numeral("0,.00") }}
            </div>
          </template>
          <template v-slot:cell(promotion_price)="{ item }">
            <InputText
              textFloat=""
              placeholder=""
              type="number"
              v-model="item.promotion_price"
              @onKeyup="handleMaxPrice(item, $event)"
              name="round_day"
            />
          </template>
          <template v-slot:cell(delete)="item">
            <b-button
              variant="link"
              @click="handleDeleteProduct(item)"
              class="text-gray"
              :disabled="isDisabled"
            >
              <font-awesome-icon icon="trash-alt" class="ft-14 ml-2"
            /></b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-modal
      v-model="importModal"
      no-close-on-backdrop
      centered
      title="Import Product"
      hide-footer
    >
      <div>
        <b-row class="no-gutters">
          <b-col md="12">
            <UploadFileV2
              textFloat="File"
              placeholder="Please Choose File"
              format="excel"
              name="file"
              text="*Please upload only file .xlsx less than 10 MB"
              isRequired
              v-on:onFileChange="onFileChange"
              v-on:delete="deleteFile"
              :fileName="filename"
              v-model="excel"
              :v="$v.excel"
              id="uploadfile"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-button size="md" class="btn-cancel" @click="importModal = false"
              >Cancel</b-button
            >
          </b-col>
          <b-col class="text-right">
            <b-button
              id="upload"
              class="main-color btn-save color-btn"
              size="md"
              :disabled="isDisable"
              @click="importProduct"
              >Upload</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <Pagination
      @handleChangeTake="handleChangeTake"
      @pagination="pagination"
      :filter="filter"
      :rows="rows"
    />

    <ModalSelectProduct
      :useBy="useBy"
      ref="modalSelectProduct"
      @submit="selectedProduct"
      :freezeProductList="freezeProductList"
      :isAlreadyUse="isDisabled"
    />
  </div>
</template>

<script>
import UploadFileV2 from "@/components/inputs/UploadFileV2";
import { required } from "vuelidate/lib/validators";
import ModalSelectProduct from "@/components/ModalSelectProduct";
export default {
  components: { ModalSelectProduct, UploadFileV2 },
  props: {
    filter: {
      required: false,
      defualt: {
        search: "",
        page: 1,
        take: 5,
      },
      type: Object,
    },
    rows: { required: false, default: 0, type: Number },
    fields: { required: false, type: Array },
    items: { required: false, type: Array },
    form: { required: false, type: Object },
    isBusy: { required: false, type: Boolean },
    useBy: { required: true },
    isDisabled: { required: false },
  },
  data() {
    return {
      importModal: false,
      excel: "",
      filename: "",
      isDisable: false,
      freezeProductList: [],
    };
  },
  validations() {
    return {
      excel: {
        required,
      },
    };
  },

  watch: {
    "form.product_id": function (newVal, oldVal) {
      this.freezeProductList = newVal;
      this.$refs.modalSelectProduct.selected = newVal;
    },
  },
  methods: {
    openModal() {
      this.$refs.modalSelectProduct.show(this.form);
      this.freezeProductList = this.form.product_id;
    },

    pagination(val) {
      this.$emit("pagination", val);
    },
    handleChangeTake(val) {
      this.$emit("handleChangeTake", val);
    },
    handleSearch() {
      this.$emit("handleSearch");
    },
    handleMaxPrice(items, e) {
      this.$nextTick(() => {
        if (Number(items.promotion_price) > items.price) {
          return (items.promotion_price = items.price);
        }
      });
    },

    onFileChange(file) {
      this.filename = file.name;
      this.isDisable = false;
      const str =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.excel = reader.result.substring(str.length, reader.result.length);
      };
    },
    deleteFile(value) {
      this.excel = null;
      this.filename = null;
      this.isDisable = true;
    },
    async importProduct() {
      let payload = {
        excel_file: this.excel,
      };
      if (this.importModal) {
        this.$bus.$emit("showLoading");
        const result = await this.axios.post(
          `/import/getproductbyimport`,
          payload
        );
        if (result.data.result) {
          let product = [
            ...this.$refs.modalSelectProduct.selected,
            ...result.data.detail.productId,
          ];
          this.selectedProduct(product);
          this.importModal = false;
        } else {
          this.errorAlert(result.data.message);
        }
        // this.$bus.$emit("showLoading");
        this.$bus.$emit("hideLoading");
      } else {
        this.importModal = true;
      }
    },
    handleDeleteProduct({ item, index }) {
      if (!this.form.delete_product_id) this.form.delete_product_id = [];
      this.form.delete_product_id.push(item.id);
      this.form.add_product_id.splice(
        this.form.add_product_id.indexOf(item.id),
        1
      );
      // this.items.splice(index, 1);
      this.$emit("syncProductSelect", this.form);
    },
    selectedProduct(product) {
      if (!this.form.add_product_id) this.form.add_product_id = [];
      if (!this.form.delete_product_id) this.form.delete_product_id = [];

      var addProduct = product.filter(
        (id) => !this.form.product_id.includes(id)
      );
      let deleteProduct = this.form.product_id.filter(
        (id) => !product.includes(id)
      );

      this.form.add_product_id = addProduct;
      this.form.delete_product_id = deleteProduct;

      this.$emit("syncProductSelect", this.form);
    },
  },
};
</script>

<style></style>
