<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <div>
        <div>
          <h1 class="mr-sm-4 header-tablepage">Header Discount Detail</h1>
        </div>
        <div class="title-tabs mt-3">Set up Header Discount</div>
        <b-container class="no-gutters bg-white py-2">
          <div class="py-3">
            <b-row>
              <b-col lg="6" md="6" sm="12" xs="12">
                <InputText
                  textFloat="Promotion Name"
                  placeholder="Promotion Name"
                  type="text"
                  name="promotions_name"
                  isRequired
                  :isValidate="$v.form.name.$error"
                  :v="$v.form.name"
                  v-model="form.name"
                />
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12">
                <InputText
                  textFloat="Promotions Ref."
                  placeholder="Promotions Ref."
                  type="text"
                  name="promotions-ref"
                  isRequired
                  :isValidate="$v.form.promotion_ref.$error"
                  :v="$v.form.promotion_ref"
                  v-model="form.promotion_ref"
                />
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12">
                <div class="mb-3">
                  <label class="main-label"
                    >Discount Value <span class="text-error">*</span></label
                  >
                  <div class="input-group">
                    <input
                      type="number"
                      class="form-control"
                      :class="{ error: $v.form.promotion_value.$error }"
                      placeholder="Discount"
                      max
                      v-model="$v.form.promotion_value.$model"
                      @keypress="isNumber($event)"
                      oninput="if( this.value.length > 6 )  this.value = this.value.slice(0,6)"
                      @paste.prevent
                    />
                    <div class="input-group-append">
                      <button
                        class="input-group-text"
                        v-if="form.promotion_discount_type_id == 1"
                        @click.prevent="form.promotion_discount_type_id = 2"
                      >
                        <font-awesome-icon
                          :icon="['fas', 'percent']"
                          class="percent"
                        />
                      </button>
                      <button
                        class="input-group-text"
                        v-else
                        @click.prevent="form.promotion_discount_type_id = 1"
                      >
                        <span class="percent">Baht</span>
                      </button>
                    </div>
                  </div>

                  <div v-if="$v.form.promotion_value.$error">
                    <div
                      class="text-danger"
                      v-if="!$v.form.promotion_value.required"
                    >
                      Please input.
                    </div>
                    <div
                      class="text-danger"
                      v-else-if="!$v.form.promotion_value.isValidPromo"
                    >
                      Discount value must less than or equal to 100%
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12">
                <div class="mb-3">
                  <label class="main-label">Condition (Minimun Paid) </label>
                  <div class="input-group">
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Discount"
                      max
                      v-model="form.minimum_amount_requirement"
                      @keypress="isNumber($event)"
                      oninput="if( this.value.length > 9 )  this.value = this.value.slice(0,9)"
                      @paste.prevent
                    />
                    <div class="input-group-append">
                      <button
                        class="input-group-text"
                        v-if="form.minimum_type_id == 0"
                        @click.prevent="convertMinimunType"
                      >
                        <span class="percent">Baht</span>
                      </button>
                      <button
                        class="input-group-text"
                        v-else
                        @click.prevent="form.minimum_type_id = 0"
                      >
                        <span class="percent">ชิ้น</span>
                      </button>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12" class="mb-3">
                <b-form-checkbox
                  size="md"
                  v-model="form.no_point"
                  :value="1"
                  :unchecked-value="0"
                  >No Point</b-form-checkbox
                >
              </b-col>
              <div class="break-normal"></div>
              <b-col lg="6" md="6" sm="12" xs="12">
                <b-form-group label="Extra Point">
                  <b-form-radio-group
                    v-model="form.is_center_point"
                    :options="extraOptions"
                    name="extra-point"
                    :disabled="form.no_point == 1"
                  ></b-form-radio-group>
                </b-form-group>
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12">
                <InputText
                  textFloat="Point"
                  placeholder="Point"
                  type="text"
                  name="condition-minimun"
                  v-model="form.point"
                  :disabled="form.no_point == 1"
                />
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12">
                <b-form-group label=" Used Point">
                  <b-form-radio-group
                    v-model="form.used_point"
                    :options="usedPointOptions"
                    @change="handleUsedPoint"
                    name="used-point"
                  ></b-form-radio-group>
                </b-form-group>
              </b-col>

              <b-col lg="6" md="6" sm="12" xs="12">
                <InputText
                  textFloat="Point Used"
                  placeholder="Point Used"
                  type="number"
                  name="point-used"
                  v-model="form.required_point"
                  :disabled="form.used_point == 0"
                  :isValidate="$v.form.required_point.$error"
                  :v="$v.form.required_point"
                />
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12">
                <b-form-group label="Birthday">
                  <b-form-radio-group
                    v-model="form.birthday"
                    :options="birthDayOptions"
                    @input="
                      form.allow_other_promotion = form.birthday == 0 ? 1 : 0
                    "
                    name="birthday"
                  ></b-form-radio-group>
                </b-form-group>
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12" class="mb-3">
                <b-form-checkbox
                  size="md"
                  class="my-3"
                  v-model="form.allow_other_promotion"
                  :value="0"
                  :disabled="form.birthday == 1"
                  :unchecked-value="1"
                  >Exclusive</b-form-checkbox
                >
              </b-col>
              <b-col cols="12" class="mb-3">
                <b-form-group label="Member Type">
                  <b-form-checkbox-group
                    id="member-type"
                    v-model="form.member_level_id"
                    :options="memberOptions"
                    name="member-type"
                    value-field="id"
                    text-field="name"
                  ></b-form-checkbox-group>
                </b-form-group>
              </b-col>
              <div class="break-normal"></div>
              <b-col lg="6" md="6" sm="12" xs="12" class="mb-md-0 mb-3">
                <label class="label-text">
                  Valid From <span class="text-danger">*</span></label
                >

                <div
                  :class="[
                    'input-container',
                    { error: $v.form.start_datetime.$error },
                  ]"
                >
                  <datetime
                    type="datetime"
                    v-model="form.start_datetime"
                    placeholder="Please Select Date"
                    format="dd/MM/yyyy (HH:mm)"
                    value-zone="Asia/Bangkok"
                    @input="handleMinDate"
                    ref="start_datetime"
                  >
                  </datetime>
                  <div
                    :class="
                      'icon-primary text-right ' + id == 0
                        ? ''
                        : 'cursor-default'
                    "
                    @click="$refs.start_datetime.isOpen = true"
                  >
                    <font-awesome-icon
                      icon="calendar-alt"
                      :class="
                        id == 0
                          ? 'pointer color-primary'
                          : 'cursor-default color-primary'
                      "
                      color="#B41BB4"
                    />
                  </div>
                </div>
                <div v-if="$v.form.start_datetime.$error">
                  <span
                    class="text-error"
                    v-if="$v.form.start_datetime.required == false"
                    >Please select date time.</span
                  >
                </div>
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12">
                <label class="label-text">
                  Valid To <span class="text-danger">*</span></label
                >
                <div
                  :class="[
                    'input-container',
                    { error: $v.form.end_datetime.$error },
                  ]"
                >
                  <datetime
                    type="datetime"
                    v-model="form.end_datetime"
                    placeholder="Please Select Date"
                    format="dd/MM/yyyy (HH:mm)"
                    value-zone="Asia/Bangkok"
                    :min-datetime="form.start_datetime"
                    ref="end_datetime"
                  >
                  </datetime>
                  <div
                    :class="
                      'icon-primary text-right ' + id == 0
                        ? ''
                        : 'cursor-default'
                    "
                    @click="$refs.end_datetime.isOpen = true"
                  >
                    <font-awesome-icon
                      icon="calendar-alt"
                      :class="
                        id == 0
                          ? 'pointer color-primary'
                          : 'cursor-default color-primary'
                      "
                      color="#B41BB4"
                    />
                  </div>
                </div>
                <div v-if="$v.form.end_datetime.$error">
                  <span
                    class="text-error"
                    v-if="$v.form.end_datetime.required == false"
                    >Please select date time.</span
                  >
                </div>
              </b-col>
            </b-row>
            <hr />
          </div>
          <div class="panelborder">
            <div class="title-tabs">D-Store Integration</div>
            <div class="px-3 py-2">
              <div class="mb-2 main-label">
                Product Promotions <span class="text-danger">*</span>
              </div>
              <b-form-group>
                <b-form-radio-group
                  id="radio-group-2"
                  v-model="form.is_all_product"
                  name="radio-sub-component"
                >
                  <b-form-radio value="1"
                    ><span class="text-black">All</span></b-form-radio
                  >
                  <b-form-radio value="0"
                    ><span class="text-black"
                      >Select a Product Lists</span
                    ></b-form-radio
                  >
                </b-form-radio-group>
              </b-form-group>
              <ProductSelect
                v-if="form.is_all_product == 0"
                useBy="promotions"
                :filter="filter"
                :fields="fields"
                :items="items"
                :rows="rows"
                :form="form"
                @syncProductSelect="syncProductSelect"
                @pagination="pagination"
                :isDisabled="isDisabled"
                @handleChangeTake="handleChangeTake"
                @handleSearch="handleSearch"
              />
              <div class="text-error" v-if="$v.form.product_id.$error">
                Please select at least 1 product.
              </div>
            </div>
            <div name="branch-select" class="px-3 py-2">
              <div class="mb-2 main-label">
                Participating Branches <span class="text-danger">*</span>
              </div>

              <ModalSelectBranch
                ref="ModalSelectBranch"
                @selectBranch="selectBranch"
                :list="branchList"
                :form="form"
                id="1"
                text="Select Branch"
                :isBranchDisable="isDisabled"
              />
              <div class="text-error" v-if="$v.form.branch_id.$error">
                Please select at least 1 branch.
              </div>
            </div>
          </div>
        </b-container>
        <div class="no-gutters bg-white mt-3 py-2 px-3">
          <b-form-checkbox
            switch
            v-model="form.status"
            class="radio-active"
            size="lg"
            :value="1"
            :unchecked-value="0"
          >
            <span class="ml-2 main-label">{{
              form.status ? "Active" : "Inactive"
            }}</span>
          </b-form-checkbox>
        </div>

        <FooterAction @submit="saveForm()" routePath="/promotions" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  email,
  requiredIf,
  minValue,
} from "vuelidate/lib/validators";
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import OtherLoading from "@/components/loading/OtherLoading";
import ProductSelect from "@/components/ProductSelect";
import ModalSelectBranch from "@/components/promotions/ModalSelectBranch";
export default {
  name: "Detail",
  components: {
    InputText,
    InputSelect,
    OtherLoading,
    ProductSelect,
    ModalSelectBranch,
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
        promotion_ref: {
          required,
        },
        promotion_value: {
          required,
          isValidPromo: function (value) {
            if (this.form.promotion_discount_type_id == 1 && value > 100)
              return false;
            return true /*  */;
          },
        },
        start_datetime: { required },
        end_datetime: { required },
        product_id: {
          required: requiredIf(function () {
            return this.form.is_all_product == 0 && this.items.length == 0;
          }),
        },
        branch_id: {
          required: requiredIf(function () {
            return (
              this.form.add_branch_id.length == 0 &&
              this.form.branch_id.length == 0
            );
          }),
        },
        required_point: {
          required: requiredIf(function () {
            return this.form.used_point == 1;
          }),
          minValue: this.form.used_point == 0 ? minValue(0) : minValue(1),
        },
        //
      },
    };
  },

  computed: {
    isEdit() {
      return this.$route.params.id == 0 ? false : true;
    },
    isDisabled() {
      return (
        this.$route.params.id != 0 &&
        this.$moment().diff(
          this.$moment(this.form.start_datetime || ""),
          "days"
        ) >= 0
      );
    },
  },
  watch: {},
  data() {
    return {
      isLoading: false,
      branchList: [],
      percentOrbath: false,
      freezeMemberLvl: [],
      form: {
        id: 0,
        allow_other_promotion: 1,
        promotion_discount_type_id: 1, // 1%, 2 baht
        name: null,
        minimum_amount_requirement: 0.0,
        minimum_type_id: 0,
        start_datetime: "",
        end_datetime: "",
        promotion_value: "",
        promotion_ref: null,
        status: 0,
        promotion_type_id: 3, //2 item, 3 header
        is_all_product: 0,
        birthday: 0,
        is_center_point: 0,
        point: 0,
        no_point: 0,
        product_group_id: 0,
        branch_group_id: 0,
        product_id: [],
        add_product_id: [],
        delete_product_id: [],
        branch_id: [],
        add_branch_id: [],
        delete_branch_id: [],
        member_level_id: [],
        add_member_level_id: [],
        delete_member_level_id: [],
        required_point: 0,
        used_point: 0,
      },
      filter: {
        search: "",
        page: 1,
        take: 5,
      },
      extraOptions: [
        { text: "Central Point", value: 1 },
        { text: "Branch Point", value: 0 },
      ],
      usedPointOptions: [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ],
      memberOptions: [],
      birthDayOptions: [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ],
      id: this.$route.params.id,
      fields: [
        {
          key: "imageUrl",
          label: "Image",
        },
        {
          key: "description",
          label: "Name/Detail",
          tdClass: "text-left",
        },
        {
          key: "ah_no",
          label: "SKU",
        },
        {
          key: "price",
          label: "Price",
        },
        {
          key: "delete",
          label: "",
        },
      ],
      items: [],
      rows: 0,
      isBusy: false,
      productSelected: [],
      delete_branch_id: [],
      productForm: {
        pageNo: 1,
        // start: 0,
        length: 5,
        promotion_id: this.$route.params.id,
        add_product_id: [],
        delete_product_id: [],
      },
      freezeProductList: [],
      isBranchDisable: false,
    };
  },
  created() {
    this.getBranchList();

    if (this.isEdit) this.getData();
    this.getMemberLevel();
  },
  destroyed() {},
  methods: {
    checkDots(str) {
      return /^[0-9]+$/.test(str);
    },
    convertMinimunType() {
      this.form.minimum_type_id = 1;

      if (!this.checkDots(this.form.minimum_amount_requirement)) {
        this.form.minimum_amount_requirement = Math.round(
          this.form.minimum_amount_requirement
        );
      }
    },
    async getMemberLevel() {
      await this.$store.dispatch("getMemberLevel");
      let data = this.$store.state.automation.memberLevel;
      if (data.result == 1) {
        this.memberOptions = data.detail;
      }
    },
    async getData() {
      const result = await this.axios(`/promotion/headerItem/${this.id}`);
      result.data.detail.used_point =
        result.data.detail.required_point > 0 ? 1 : 0;
      this.form = result.data.detail;

      this.freezeMemberLvl = result.data.detail.member_level_id;
      this.freezeProductList = result.data.detail.product_id;
      this.getProductList();
      this.isBranchDisable = true;
    },

    async syncProductSelect(productList) {
      this.productForm.add_product_id = productList.add_product_id;
      this.productForm.delete_product_id = productList.delete_product_id;
      this.getProductList();
    },
    async getProductList() {
      let payload = { ...this.productForm };
      payload.search = this.filter.search;
      payload.pageNo = this.filter.page;
      payload.length = this.filter.take;

      const result = await this.axios.post(
        `/promotion/ProductByPromotion`,
        payload
      );
      this.items = result.data.detail.product_list;
      this.rows = result.data.detail.total_product;
    },
    async getBranchList() {
      const result = await this.axios(`/branch/group`);
      var branchSelect = [];
      for (let branch of result.data.detail) {
        branch.selected_count = 0;
        for (const check of branch.branchList) {
          if (this.form.branch_id.includes(check.id)) {
            branch.selected_count += 1;
            check.branch_group = branch.name;
            check.is_check = true;
            if (
              this.$route.params.id != 0 &&
              this.$moment().diff(
                this.$moment(this.form.start_datetime || ""),
                "days"
              ) >= 0
            ) {
              check.is_disabled = true;
            }
            branchSelect.push(check);
          }
        }
      }
      // this.rows = branchSelect.length;
      this.branchList = result.data.detail;
    },
    handleSearch() {
      this.getProductList();
    },
    handleMinDate() {
      let diff = this.$moment(this.form.end_datetime).diff(
        this.$moment(this.form.start_datetime),
        "min"
      );

      if (diff < 0) this.form.end_datetime = "";
    },
    pagination(val) {
      this.filter.page = val;
      this.getProductList();
    },
    handleChangeTake() {
      this.getProductList();
    },
    keypressDot(e) {
      if (e.target.type == "number") {
        if (this.form.minimum_type_id == 1 && e.charCode === 46) {
          e.preventDefault();
        }
      }
    },
    selectBranch(val) {
      const addID = val.filter((el) => !this.form.branch_id.includes(el));
      const deleteID = this.form.branch_id.filter((i) => !val.includes(i));

      this.form.add_branch_id = addID;
      // this.form.branch_id = val;
      this.form.delete_branch_id = deleteID;
    },

    async saveForm() {
      const deleteMember = this.freezeMemberLvl.filter(
        (id) => !this.form.member_level_id.includes(id)
      );
      const addMember = this.form.member_level_id.filter(
        (id) => !this.freezeMemberLvl.includes(id)
      );
      this.form.add_member_level_id = addMember;
      this.form.delete_member_level_id = deleteMember;

      this.$v.form.$touch();

      if (this.$v.form.$error || !this.$v.form.promotion_value.isValidPromo) {
        return;
      }
      this.$bus.$emit("showLoading");
      const requestPath = this.isEdit
        ? this.axios.put(`/promotion/headerItem`, this.form)
        : this.axios.post(`/promotion/headerItem`, this.form);
      const result = await requestPath;

      if (result.data.result) {
        this.successAlert().then(() => this.$router.push("/promotions"));
      } else {
        this.errorAlert(result.data.message);
      }
      this.$bus.$emit("hideLoading");
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;

      var charCode = evt.which ? evt.which : evt.keyCode;

      if (charCode > 31 && (charCode < 46 || charCode > 57 || charCode == 47)) {
        evt.preventDefault();
      } else {
        this.keypressDot(evt);

        return true;
      }
    },
    handleUsedPoint(val) {
      if (val == 0) this.form.required_point = 0;
      this.form.used_point = val;
    },
  },
};
</script>

<style lang="scss" scoped>

.img-box-preview {
  width: 100%;
  padding-bottom: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  border: 2px dashed #bfbfbf;
  position: relative;
}
.reset-password {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
  font-size: 12px;
}
::v-deep h2#swal2-title.swal2-title {
  font-size: 24px !important;
}
::v-deep .price-baht {
  &::before {
    content: "฿";
  }
}
::v-deep .font-bold {
  font-weight: bold;
}
.select-branch {
  display: flex;
  width: max-content;
  align-items: center;
}
.panel-select-branch {
  gap: 10px;
  align-items: center;
  display: flex;
}
.panelborder {
  border: 1px solid #d8dbe0;
}
</style>
